@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #020617;
  max-height: 100dvh;
  line-height: 1.6;
  letter-spacing: 0.7px;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-overlay {
  background: rgb(2,6,23);
  background: linear-gradient(180deg, rgba(2,6,23,1) 0%, rgba(0,0,0,0.7) 70%, rgba(255,255,255,0) 100%);  
}

@media (min-width: 768px) { 
  .main-overlay {
    background: rgb(2,6,23);
    background: linear-gradient(90deg, rgba(2,6,23,1) 0%, rgba(0,0,0,0.7) 50%, rgba(255,255,255,0) 100%);  
  }
}

